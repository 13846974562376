define("discourse/plugins/discourse-codebytes-plugin/lib/discourse-markdown/code-bytes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  const CODEBYTE_CLASS = "d-codebyte";
  const blockRule = {
    tag: "codebyte",
    replace(state, tagInfo, content) {
      let token = state.push("codebyte_open", "div", 1);
      token.attrs = [["class", CODEBYTE_CLASS]];
      if (tagInfo.attrs.language) {
        token.attrs.push(["data-language", tagInfo.attrs.language]);
      }
      token = state.push("text", "", 0);
      token.content = content;
      state.push("codebyte_close", "div", -1);
      return true;
    }
  };
  function setup(helper) {
    helper.registerOptions((opts, siteSettings) => {
      opts.features["code-bytes"] = !!siteSettings.code_bytes_enabled;
    });
    helper.registerPlugin(md => {
      md.block.bbcode.ruler.push("block-codebyte", blockRule);
    });
    helper.allowList([`div.${CODEBYTE_CLASS}`, `span.${CODEBYTE_CLASS}`, "span[data-*]"]);
  }
});